import { animated, useChain, useSpring, useSpringRef, useSprings } from 'react-spring'
import { routeMap } from '/routeMap'

import matchingPartner from '/images/logos/matching-partner-logo.raw.svg'
import astonMartin from '/images/logos/aramco.raw.svg'
import circle8 from '/images/logos/circle-8-light.raw.svg'

import styles from './Logo.css'

export function Logo({ layoutClassName }) {
  const { refs, springs } = useAnimations()

  useChain(refs, [0, 0.75], 500)

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div className={styles.logos}>
        <LogoAsset
          alignment='left'
          src={astonMartin}
          href={routeMap.collab()}
          alt='Aramco Formula One Team Logo'
          layoutClassName={styles.aramcoLayout}
          animation={springs.logos.aramco}
        />

        <Divider layoutClassName={styles.dividerLayout} />

        <LogoAsset
          alignment='right'
          src={circle8}
          alt='Circle8 logo'
          href={routeMap.home()}
          layoutClassName={styles.circle8Layout}
          animation={springs.logos.circle8}
        />
      </div>

      <LogoAsset
        alt='Global talent matching partner'
        layoutClassName={styles.matchingPartnerLayout}
        src={matchingPartner}
        animation={springs.matchingPartner}
      />
    </div>
  )
}

function LogoAsset({
  src,
  alt,
  href = undefined,
  alignment = undefined,
  animation = undefined,
  layoutClassName = undefined
}) {
  const Base = href ? 'a' : 'div'

  return (
    <Base
      className={cx(styles.componentAsset, styles[alignment], layoutClassName)}
      {...{ href }}
    >
      <animated.span
        role='presentation'
        className={styles.logo}
        dangerouslySetInnerHTML={{ __html: src }}
        style={animation}
        aria-label={alt}
      />
    </Base>
  )
}

function Divider({ layoutClassName }) {
  return (
    <div
      aria-hidden='true'
      role='presentation'
      className={cx(styles.componentDivider, layoutClassName)}
    />
  )
}

function useAnimations() {
  const logoSpringRef = useSpringRef()
  const [[aramco, circle8]] = useSprings(2, i => {
    const isOdd = i === 1
    const direction = isOdd ? -1 : 1

    return {
      ref: logoSpringRef,
      from: { opacity: 0, x: `${100 * direction}%` },
      to: { opacity: 1, x: '0%' }
    }
  })

  const matchingSpringRef = useSpringRef()
  const matchingPartner = useSpring({
    ref: matchingSpringRef,
    from: { opacity: 0, y: '-100%' },
    to: { opacity: 1, y: '0%' }
  })

  return {
    refs: [
      logoSpringRef,
      matchingSpringRef
    ],
    springs: {
      logos: { aramco, circle8 },
      matchingPartner
    }
  }
}
